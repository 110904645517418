import { createTheme } from "@material-ui/core/styles";

export default createTheme({
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          background: "#FFFFFF",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        },
      },
    },
    MuiDropzoneSnackbar: {
      infoAlert: {
        backgroundColor: "#f44336",
      },
    },
    MuiAccordion: {
      root: {
        "&:before": {
          opacity: 0,
        },
      },
    },

    MuiAccordionSummary: {
      content: {
        margin: "0.3rem 0 0.3rem 0",
      },
    },

    MuiRating: {
      root: {
        color: "#CC0000",
      },
    },
  },
  typography: {
    fontFamily: '"Lato", "Roboto", "Helvetica", "Arial", sans-serif',
  },
  palette: {
    primary: {
      main: "#0DB14B",
      light: "#5be479",
      dark: "#00801d",
    },
    secondary: {
      main: "#74378E",
      light: "#FFFFFF",
      dark: "#CCCCCC",
    },
    error: {
      main: "#F44336",
      light: "#ff7961",
      dark: "#ba000d",
    },
    background: {
      default: "#FFFFFF",
    },
  },
  breakpoints: {
    values: {
      xs: 280.05 /* TODO: Change to 0 when TestimonialsCardGrid is redone */,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});
